exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuality-js": () => import("./../../../src/pages/aktuality.js" /* webpackChunkName: "component---src-pages-aktuality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-prodej-js": () => import("./../../../src/pages/prodej.js" /* webpackChunkName: "component---src-pages-prodej-js" */),
  "component---src-pages-servis-js": () => import("./../../../src/pages/servis.js" /* webpackChunkName: "component---src-pages-servis-js" */),
  "component---src-pages-stavby-js": () => import("./../../../src/pages/stavby.js" /* webpackChunkName: "component---src-pages-stavby-js" */)
}

